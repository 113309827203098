import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
// Create styles
const Quixote = ({ invoiceData, companyInfo, getInfo, getSummInfo }) => {
  const reportData = invoiceData && invoiceData.length && invoiceData;

  let grandtotalOrderQty = 0;
  let grandtotalPaymentAmt = 0;
  let grandtotalPurchaseAmt = 0;
  let grandtotalBalanceAmt = 0;

  let count = 0;

  const BuyerData = ({ i2 }) => {
    return (
      <View
        wrap={false}
        break={count > 13}
        style={{
          borderBottom: "1px solid black",
          borderLeft: "1px solid black",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Text
          style={{
            padding: "2px",
            fontSize: "8px",
            height: "auto",
            width: "12%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.voucher_date}
        </Text>
        <Text
          style={{
            padding: "2px",
            fontSize: "8px",
            height: "auto",
            width: "20%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.purticulars}
        </Text>
        <Text
          style={{
            padding: "2px",
            fontSize: "8px",
            height: "auto",
            width: "20%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.account_name}
        </Text>

        <Text
          style={{
            padding: "2px",
            fontSize: "8px",
            height: "auto",
            width: "10%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.voucher_no}
        </Text>
        <Text
          style={{
            padding: "2px",
            fontSize: "8px",
            height: "auto",
            width: "12%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {Number(i2?.payment_amt).toFixed(2)}
        </Text>
        <Text
          style={{
            padding: "2px",
            fontSize: "8px",
            height: "auto",
            width: "12%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {Number(i2?.receive_amt).toFixed(2)}
        </Text>
        <Text
          style={{
            padding: "2px",
            fontSize: "8px",
            height: "auto",
            width: "14%",
            borderRight: "1px solid black",
            textAlign: "right",
          }}
        >
          {Number(i2?.cumulative_sum).toFixed(2)}
        </Text>
      </View>
    );
  };

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  return (
    <Document>
      <Page orientation="portrait" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}

        {reportData &&
          reportData?.map((supplier, index) => {
            const filtering =
              supplier &&
              supplier?.data?.filter(
                (item) => item?.purticulars?.toLowerCase() === "opening"
              );

            const newArr =
              supplier &&
              supplier?.data?.filter(
                (item) => item?.purticulars?.toLowerCase() !== "opening"
              );

            const subtotalOrderQty =
              supplier?.data && supplier?.data.length
                ? supplier?.data.reduce(
                    (totalQty, currentQty) =>
                      totalQty + Number(currentQty?.quantity),
                    0
                  )
                : 0;

            grandtotalOrderQty += subtotalOrderQty;

            const subtotalPaymentAmt =
              supplier?.data && supplier?.data.length
                ? supplier?.data.reduce(
                    (totalValue, currentValue) =>
                      totalValue + Number(currentValue?.payment_amt),
                    0
                  )
                : null;

            grandtotalPaymentAmt += subtotalPaymentAmt;

            const subtotalReceiveAmt =
              supplier?.data && supplier?.data.length
                ? supplier?.data.reduce(
                    (totalValue, currentValue) =>
                      totalValue + Math.abs(Number(currentValue?.receive_amt)),
                    0
                  )
                : null;

            const subtotalBalanceAmt =
              supplier?.data && supplier?.data.length
                ? supplier?.data.reduce(
                    (totalValue, currentValue) =>
                      totalValue + Number(currentValue?.cumulative_sum),
                    0
                  )
                : null;
            grandtotalBalanceAmt += subtotalBalanceAmt;

            return (
              <View key={index} break={index > 0}>
                <View wrap={false}>
                  <View
                    style={{
                      borderBottom: "3px solid black",
                      paddingBottom: 5,
                    }}
                  >
                    <View style={{ margin: "auto" }}>
                      <Text
                        style={{
                          fontSize: 20,
                          fontWeight: 900,
                          fontFamily: "Times",
                        }}
                      >
                        {companyInfo?.[0]?.["company_name"]}
                      </Text>
                    </View>
                    <View style={{ margin: "auto" }}>
                      <Text style={{ fontSize: 12 }}>
                        {companyInfo?.[0]?.["street"]}
                        {", "}
                        {companyInfo?.[0]?.["city"]}
                        {", "}
                        {companyInfo?.[0]?.["country"]}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      height: "auto",
                      marginTop: "2px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "13px",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        textAlign: "center",
                      }}
                    >
                      Book Ledger
                    </Text>
                    <Text
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        margin: "auto",
                        borderBottom: "1px solid black",
                        width: 150,
                      }}
                    >
                      {""}
                    </Text>
                    <Text
                      style={{
                        fontSize: "10px",
                        textAlign: "center",
                        padding: "1px",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          fontFamily: "Times",
                        }}
                      >
                        From
                      </Text>{" "}
                      {getSummInfo &&
                        new Date(
                          getSummInfo?.["p_from_date"]
                        ).toLocaleDateString(["es-CL", "id"])}
                      <Text
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          fontFamily: "Times",
                        }}
                      >
                        {" "}
                        To
                      </Text>{" "}
                      {getSummInfo &&
                        new Date(getSummInfo?.["p_to_date"]).toLocaleDateString(
                          ["es-CL", "id"]
                        )}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <View>
                      <Text style={{ fontSize: "9px" }}>
                        <Text style={{ fontWeight: 900, fontFamily: "Times" }}>
                          Print Date:
                        </Text>{" "}
                        {new Date(defaultValue).toLocaleDateString([
                          "es-CL",
                          "id",
                        ])}
                      </Text>
                      <Text style={{ fontSize: "9px" }}>
                        <Text style={{ fontWeight: 900, fontFamily: "Times" }}>
                          Print By:
                        </Text>{" "}
                        {`${getInfo?.first_name ? getInfo?.first_name : ""} ${
                          getInfo?.last_name ? getInfo?.last_name : ""
                        }`}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* TABLE PART */}

                <View style={{ marginTop: "20px" }}>
                  <View
                    wrap={false}
                    fixed
                    style={{
                      borderTop: "1px solid black",
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      backgroundColor: "#d3cfcf",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Text
                      style={{
                        padding: "2px",
                        fontSize: "9px",
                        fontFamily: "Times",
                        fontWeight: "bold",
                        height: "auto",
                        width: "12%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Date
                    </Text>
                    <Text
                      style={{
                        padding: "2px",
                        fontSize: "9px",
                        fontFamily: "Times",
                        fontWeight: "bold",
                        height: "auto",
                        width: "20%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      To/By
                    </Text>
                    <Text
                      style={{
                        padding: "2px",
                        fontSize: "9px",
                        fontFamily: "Times",
                        fontWeight: "bold",
                        height: "auto",
                        width: "20%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Account Head
                    </Text>
                    <Text
                      style={{
                        padding: "2px",
                        fontSize: "9px",
                        fontFamily: "Times",
                        fontWeight: "bold",
                        height: "auto",
                        width: "10%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Voucher No.
                    </Text>

                    <Text
                      style={{
                        padding: "2px",
                        fontSize: "9px",
                        fontFamily: "Times",
                        fontWeight: "bold",
                        height: "auto",
                        width: "12%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Debit
                    </Text>
                    <Text
                      style={{
                        padding: "2px",
                        fontSize: "9px",
                        fontFamily: "Times",
                        fontWeight: "bold",
                        height: "auto",
                        width: "12%",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Credit
                    </Text>

                    <Text
                      style={{
                        padding: "2px",
                        fontSize: "9px",
                        fontFamily: "Times",
                        fontWeight: "bold",
                        height: "auto",
                        width: "14%",
                        borderRight: "1px solid black",
                        textAlign: "right",
                      }}
                    >
                      Balance
                    </Text>
                  </View>
                  <View>
                    <View>
                      <View
                        wrap={false}
                        style={{
                          borderBottom: "1px solid black",
                          borderLeft: "1px solid black",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <Text
                          style={{
                            padding: "2px",
                            fontSize: "8px",
                            height: "auto",
                            width: "12%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {}
                        </Text>
                        <Text
                          style={{
                            padding: "2px",
                            fontSize: "8px",
                            height: "auto",
                            width: "20%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {""}
                        </Text>
                        <Text
                          style={{
                            padding: "2px",
                            fontSize: "8px",
                            fontFamily: "Times",
                            fontWeight: "bold",
                            height: "auto",
                            width: "20%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {filtering?.[0]?.account_name}
                        </Text>

                        <Text
                          style={{
                            padding: "2px",
                            fontSize: "8px",
                            height: "auto",
                            width: "10%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {""}
                        </Text>
                        <Text
                          style={{
                            padding: "2px",
                            fontSize: "8px",
                            fontFamily: "Times",
                            fontWeight: "bold",
                            height: "auto",
                            width: "24%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {filtering?.[0]?.purticulars}
                        </Text>

                        <Text
                          style={{
                            padding: "2px",
                            fontSize: "8px",
                            height: "auto",
                            width: "14%",
                            borderRight: "1px solid black",
                            textAlign: "right",
                          }}
                        >
                          {Number(filtering?.[0]?.cumulative_sum).toFixed(2)}
                        </Text>
                      </View>

                      {newArr && newArr?.length
                        ? newArr?.map((i2, i) => {
                            count = count + 1;
                            return (
                              <View key={i}>
                                <BuyerData newArr={newArr} key={i} i2={i2} />
                              </View>
                            );
                          })
                        : null}
                      <View
                        style={{
                          borderBottom: "1px solid black",
                          borderLeft: "1px solid black",
                          flexDirection: "row",
                          width: "100%",
                        }}
                        wrap={false}
                      >
                        <Text
                          style={{
                            padding: "2px",
                            fontSize: "10px",
                            height: "auto",
                            width: "12%",
                            color: "red",
                            fontWeight: "bold",
                            fontFamily: "Times",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {}
                        </Text>
                        <Text
                          style={{
                            padding: "2px",
                            fontSize: "8px",
                            height: "auto",
                            width: "20%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {""}
                        </Text>
                        <Text
                          style={{
                            padding: "2px",
                            fontSize: "10px",
                            height: "auto",
                            color: "red",
                            fontWeight: "bold",
                            fontFamily: "Times",
                            width: "20%",
                            borderRight: "1px solid black",
                            textAlign: "right",
                          }}
                        >
                          {"Sub Total"}
                        </Text>

                        <Text
                          style={{
                            padding: "2px",
                            fontSize: "8px",
                            height: "auto",
                            width: "10%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {""}
                        </Text>
                        <Text
                          style={{
                            padding: "2px",
                            fontSize: "8px",
                            height: "auto",
                            width: "12%",
                            color: "red",
                            fontWeight: "bold",
                            fontFamily: "Times",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {subtotalPaymentAmt?.toFixed(2)}
                        </Text>
                        <Text
                          style={{
                            padding: "2px",
                            fontSize: "8px",
                            height: "auto",
                            width: "12%",
                            color: "red",
                            fontWeight: "bold",
                            fontFamily: "Times",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {subtotalReceiveAmt?.toFixed(2)}
                        </Text>

                        <Text
                          style={{
                            padding: "5px 2px 5px 0",
                            fontSize: "8px",
                            height: "auto",
                            width: "14%",
                            color: "red",
                            fontWeight: "bold",
                            fontFamily: "Times",
                            borderRight: "1px solid black",
                            textAlign: "right",
                          }}
                        >
                          {/* {subtotalBalanceAmt?.toFixed(2)} */}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            );
          })}

        <View
          wrap={false}
          style={{
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            borderTop: "1px solid black",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Text
            style={{
              padding: "2px",
              fontSize: "10px",
              height: "auto",
              width: "12%",
              color: "green",
              fontWeight: "bold",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>
          <Text
            style={{
              padding: "2px",
              fontSize: "8px",
              height: "auto",
              width: "20%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>
          <Text
            style={{
              padding: "2px",
              fontSize: "10px",
              height: "auto",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              width: "20%",
              borderRight: "1px solid black",
              textAlign: "right",
            }}
          >
            {"Grand Total"}
          </Text>

          <Text
            style={{
              padding: "2px",
              fontSize: "8px",
              height: "auto",
              width: "10%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          ></Text>

          <Text
            style={{
              padding: "2px",
              fontSize: "8px",
              height: "auto",
              width: "12%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {grandtotalPaymentAmt?.toFixed(2)}
          </Text>
          <Text
            style={{
              padding: "2px",
              fontSize: "8px",
              height: "auto",
              width: "12%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {grandtotalPurchaseAmt?.toFixed(2)}
          </Text>
          <Text
            style={{
              padding: "5px 2px 5px 0",
              fontSize: "8px",
              height: "auto",
              width: "14%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "right",
            }}
          >
            {/* {grandtotalBalanceAmt?.toFixed(2)} */}
          </Text>
        </View>

        {/* Signature part */}
        {/* <View
          fixed
          style={{
            flexDirection: "row",
            bottom: 0,
            marginTop: 60,
            justifyContent: "space-around",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Prepared By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Checked By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Approved By
              </Text>
            </View>
          </View>
        </View> */}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

Font.registerHyphenationCallback((word) => {
  if (word.length > 16) {
    return chunkSubstr(word, 14);
  } else {
    return [word];
  }
});

Font.register({
  family: "Helvetica",
  fonts: [
    {
      src: "/assets/fonts/Helvetica.ttf",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/Helvetica Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Times",
  fonts: [
    {
      src: "/assets/fonts/Times-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  body: {
    paddingTop: 25,
    paddingBottom: 60,
    paddingHorizontal: 20,
    minWidth: "110vw",
    fontFamily: "Helvetica",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
