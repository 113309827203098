import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";

import AppMenuItem from "./MenuItems";

const Module = (props) => {
  const classes = useStyles();
  const { modules, sidebarOpen, handleClose,
  setToggleMenuWithHover } = props;
  const [data, setData] = useState("");
  const [value, setValue] = useState({
    subModule: [],
  });

  const [activeRoute, setActiveRoute] = useState(undefined);
  const toggleMenu = (index) =>
    setActiveRoute(activeRoute === index ? undefined : index);

  const tree = (function (modules, root) {
    var o = {};
    modules &&
      modules.forEach(function (a) {
        a.children = o[a.id] && o[a.id].children;
        o[a.id] = a;
        o[a.parent_menu] = o[a.parent_menu] || {};
        o[a.parent_menu].children = o[a.parent_menu].children || [];
        o[a.parent_menu].children.push(a);
      });
    return o[root];
  })(modules, 0);


  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {/* <AppMenuItem {...appMenuItems[0]} /> */}
      <div style={{ marginTop: 10, marginBottom: 30 }}>
        {tree
          ? tree.children
              .sort((a, b) => a.psl - b.psl)
              .map((item, index) => (
                <div style={{ marginRight: 10, marginLeft: 9, marginTop: 2 }}>
                  <AppMenuItem
                    {...item}
                    key={index}
                    handleClose={handleClose}
                    setToggleMenuWithHover=
                    {setToggleMenuWithHover}
                    sidebarOpen={sidebarOpen}
                    activeRoute={activeRoute}
                    toggleMenu={toggleMenu}
                  />
                  <Divider />
                </div>
              ))
          : null}
      </div>
    </List>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    appMenu: {
      width: "100%",
      height: 1500,
      backgroundColor: "#353839",
      // backgroundColor: "#1C6758",
      color: "white",
    },
  })
);

export default Module;
