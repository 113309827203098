import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_APPROVAL_FORM_SETUPS,
  BEGIN_APPROVAL_FORM_SETUPS_DOWNLOAD,
  BEGIN_EDIT_APPROVAL_FORM_SETUPS,
  RETRIEVE_APPROVAL_FORM_SETUPS_DELETE
} from "../../constants/modules/approvalFormSetupConstant";

import {
  addApprovalFormSetupErrorAction,
  addApprovalFormSetupOkAction,
  deleteApprovalFormSetupErrorAction,
  deleteApprovalFormSetupOkAction,
  downloadApprovalFormSetupsErrorAction,
  downloadApprovalFormSetupsOkAction,
  editApprovalFormSetupErrorAction,
  editApprovalFormSetupOkAction
} from "../../actions/modules/approvalFormSetupAction";

import {
  addApprovalFormSetupDB,
  deleteApprovalFormSetupDB,
  editApprovalFormSetupDB,
  retrieveApprovalFormSetupsData
} from "../../../config/apiCalls/approvalFormSetupAPICall";

// Retrieve products
// worker saga
export function* retrieveApprovalFormSetups() {
  try {
    const response = yield call(retrieveApprovalFormSetupsData);

    if (response.status === 200) {
      yield put(downloadApprovalFormSetupsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadApprovalFormSetupsErrorAction());
  }
}

// Create new product
// worker saga
export function* addApprovalFormSetup(action) {
  const approvalFormSetup = action.approvalFormSetup;
  try {
    const response = yield call(addApprovalFormSetupDB, approvalFormSetup);
    if (response.status === 201) {
      yield delay(500);

      yield put(addApprovalFormSetupOkAction(approvalFormSetup));
      toast.success("Approval Form Setup created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addApprovalFormSetupErrorAction(true));
    toast.error("Approval Form Setup created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteApprovalFormSetup(action) {
  const id = action.payload;
  try {
    yield call(deleteApprovalFormSetupDB, id);
    yield deleteApprovalFormSetupOkAction();
    toast.success("Approval Form Setup deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteApprovalFormSetupErrorAction();
  }
}

// Edit product
// worker saga
export function* editApprovalFormSetup(action) {
  const approvalFormSetup = action.approvalFormSetup;

  try {
    yield call(editApprovalFormSetupDB, approvalFormSetup);
    yield editApprovalFormSetupOkAction(approvalFormSetup);
    // Alert
    // Alert
    toast.success("Approval Form Setup has been updated!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editApprovalFormSetupErrorAction();
  }
}

export function* watchApprovalFormSetupsAsync() {
  yield takeLatest(
    BEGIN_APPROVAL_FORM_SETUPS_DOWNLOAD,
    retrieveApprovalFormSetups
  );
  yield takeLatest(ADD_APPROVAL_FORM_SETUPS, addApprovalFormSetup);
  yield takeLatest(
    RETRIEVE_APPROVAL_FORM_SETUPS_DELETE,
    deleteApprovalFormSetup
  );
  yield takeLatest(BEGIN_EDIT_APPROVAL_FORM_SETUPS, editApprovalFormSetup);
}
