import { call, put, takeEvery } from "redux-saga/effects";
import { BEGIN_MENUS_DOWNLOAD } from "../constants/menuConstants";

import {
  downloadModulesErrorAction,
  downloadModulesOkAction,
} from "../actions/menuActions";

import { retrieveAppModulesData } from "../../config/apiCalls";

// Retrieve products
// worker saga
export function* retrieveMenus(action) {
  const menus = action.menus;
  try {
    const { data } = yield call(retrieveAppModulesData, menus);
    yield put(downloadModulesOkAction(data));
  } catch (error) {
    yield put(downloadModulesErrorAction());
  }
}

export function* watchModulesAsync() {
  yield takeEvery(BEGIN_MENUS_DOWNLOAD, retrieveMenus);
}
