import { toast } from "react-toastify";
import {
  call,
  delay,
  put,
  takeLatest
} from "redux-saga/effects";
import {
  ADD_USER_ACCESS,
  BEGIN_EDIT_USER_ACCESS,
  BEGIN_USER_ACCESS_DOWNLOAD,
  RETRIEVE_USER_ACCESS_BULK_DELETE,
  RETRIEVE_USER_ACCESS_BY_ID,
  RETRIEVE_USER_ACCESS_DELETE,
} from "../../constants/modules/userAccessConstants";

import {
  addUserAccessErrorAction,
  addUserAccessOkAction,
  bulkDeleteUserAccessErrorAction,
  bulkDeleteUserAccessOkAction,
  deleteUserAccessErrorAction,
  deleteUserAccessOkAction,
  downloadUserAccessesErrorAction,
  downloadUserAccessesOkAction,
  editUserAccessErrorAction,
  editUserAccessOkAction,
  getUserAccessByIdErrorAction,
  getUserAccessByIdOkAction,
} from "../../actions/modules/userAccessActions";

import {
  addUserAccessDB,
  bulkDeleteUserAccessDB,
  deleteUserAccessDB,
  editUserAccessDB,
  retrieveAccessByUserIdDB,
  retrieveUserAccessesData,
} from "../../../config/apiCalls";

// Retrieve products
// worker saga
export function* retrieveUserAccesses() {
  try {
    const response = yield call(retrieveUserAccessesData);
    if (response.status === 200) {
      yield put(downloadUserAccessesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadUserAccessesErrorAction());
  }
}

export function* getUserAccessesById(action) {
  const userAccess = action.userAccess;
  try {
    const response = yield call(retrieveAccessByUserIdDB, userAccess);
    if (response.status === 200) {
      yield put(getUserAccessByIdOkAction(response.data));
    }
  } catch (error) {
    yield put(getUserAccessByIdErrorAction());
  }
}

// Create new product
// worker saga
export function* addUserAccess(action) {
  const userAccess = action.userAccess;


  try {
    const response = yield call(addUserAccessDB, userAccess);
    if (response.status === 200) {
      yield delay(500);
      yield put(addUserAccessOkAction(userAccess));
      toast.success("User Access Updated successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addUserAccessErrorAction(true));
    toast.error("User Access Updated failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteUserAccess(action) {
  const id = action.payload;
  try {
    yield call(deleteUserAccessDB, id);
    yield deleteUserAccessOkAction();
    toast.success("User Access deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield put(deleteUserAccessErrorAction());
    toast.error("User Access deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* bulkUserAccessDelete(action) {
  const userAccess = action.userAccess;

  try {
    const response = yield call(bulkDeleteUserAccessDB, userAccess);
    if (response.status === 200) {
      yield delay(500);
      yield put(bulkDeleteUserAccessOkAction(userAccess));
      toast.success("User Accesses deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteUserAccessErrorAction(true));
    toast.error("User Accesses deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Edit product
// worker saga
export function* editUserAccess(action) {
  const userAccess = action.userAccess;

  try {
    const response = yield call(editUserAccessDB, userAccess);

    if (response.status === 201) {
      yield delay(500);
      yield put(editUserAccessOkAction(userAccess));
      toast.success("User Access edited successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(editUserAccessErrorAction());
    toast.error("User Access edited failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchUserAccessesAsync() {
  yield takeLatest(BEGIN_USER_ACCESS_DOWNLOAD, retrieveUserAccesses);
  yield takeLatest(RETRIEVE_USER_ACCESS_BY_ID, getUserAccessesById);
  yield takeLatest(ADD_USER_ACCESS, addUserAccess);
  yield takeLatest(RETRIEVE_USER_ACCESS_DELETE, deleteUserAccess);
  yield takeLatest(BEGIN_EDIT_USER_ACCESS, editUserAccess);
  yield takeLatest(RETRIEVE_USER_ACCESS_BULK_DELETE, bulkUserAccessDelete);
}
