import { makeStyles } from "@material-ui/core/styles";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { drawerWidth } from "../../styleVariables";

const useStyles = makeStyles((theme) => ({
  // content: {
  //   backgroundColor: theme.palette.background.default,
  //   minWidth: 0,
  //   width: "100%",
  //   height: "calc(100vh - 64px)",
  //   position: "relative",
  //   paddingLeft: theme.spacing(1),
  //   paddingRight: theme.spacing(1),
  //   paddingTop: theme.spacing(1),
  //   display: "block",
  //   [theme.breakpoints.up("sm")]: {
  //     overflowY: "auto",
  //     overflowX: "hidden",
  //   },
  //   transition: theme.transitions.create("margin", {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen,
  //   }),
  //   "-webkit-overflow-scrolling": "touch",
  // },
  content: {
    flexGrow: 1,
    width: `calc(100vw - 260px)`,
    height: "calc(100vh - 64px)",
    position: "relative",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    display: "block",
    [theme.breakpoints.up("sm")]: {
      overflowY: "auto",
      overflowX: "hidden",
    },
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "-webkit-overflow-scrolling": "touch",
    // minHeight: "100vh",
  },
  contentShift: {
    width: `calc(100vw - ${250 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  "content-left": {
    [theme.breakpoints.up("md")]: {
      marginLeft: -drawerWidth,
    },
  },
  "content-right": {
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  "contentShift-left": {
    marginLeft: 0,
  },
  "contentShift-right": {
    marginRight: 0,
  },
  fab: {
    flexGrow: 1,
    position: "relative",
    top: theme.spacing(1),
    width: "100%",
    height: theme.spacing(1),
  },
  fabFixed: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    cursor: "pointer",
    backgroundColor: "red",
    borderRadius: "50%",
    marginLeft: -23,
    zIndex: "1300",
    boxShadow: "1px 1px 5px #F4AAB9",
  },
  fabShift: {
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const Workspace = ({ children, opened, toggleDrawer }) => {
  const classes = useStyles();
  return (
    <main
      // className={classNames(classes.content, classes[`content-left`], {
      //   [classes.contentShift]: opened,
      //   [classes[`contentShift-left`]]: opened,
      // })}
      className={classNames(classes.content, {
        [classes.contentShift]: opened,
      })}
    >
      <div
        className={classNames(classes.fab, {
          [classes.fabShift]: opened,
        })}
      >
        <div
          className={classes.fabFixed}
          onClick={() => {
            toggleDrawer();
          }}
        >
          {opened ? (
            <NavigateBeforeIcon style={{ color: "white", fontSize: 22 }} />
          ) : (
            <NavigateNextIcon style={{ color: "white", fontSize: 22 }} />
          )}
        </div>
      </div>
      {children}
    </main>
  );
};

Workspace.prototypes = {
  children: PropTypes.node.isRequired,
  opened: PropTypes.bool,
};

export default Workspace;
