import axiosClient from "../axiosClient";

/*-------------------------------------------------*/
/*              PRODUCT BRANDS API CALLS             */
/*-------------------------------------------------*/

export async function bulkDeleteProductBrandDB(ids) {
  return await axiosClient.post(`/product_brands/bulk_delete`, ids);
}

export async function retrieveProductBrandsData() {
  return await axiosClient.get("/product_brands");
}

export async function addProductBrandDB(value) {
  var bodyFormData = new FormData();

  bodyFormData.append("brand_name", value.brand_name);
  bodyFormData.append("logo", value.logo);
  bodyFormData.append("created_by", value.created_by);
  bodyFormData.append("created_date", value.created_date);
  bodyFormData.append("modified_by", value.modified_by);
  bodyFormData.append("modified_date", value.modified_date);

  return await axiosClient.post("/product_brands", bodyFormData);
}

export async function deleteProductBrandDB(id) {
  return await axiosClient.delete(`/product_brands/${id}`);
}

export async function editProductBrandDB(value) {
  var bodyFormData = new FormData();
  bodyFormData.append("brand_name", value.brand_name);
  bodyFormData.append("logo", value.logo);
  bodyFormData.append("created_by", value.created_by);
  bodyFormData.append("created_date", value.created_date);
  bodyFormData.append("modified_by", value.modified_by);
  bodyFormData.append("modified_date", value.modified_date);

  return await axiosClient.put(`/product_brands/${value.id}`, bodyFormData);
}
