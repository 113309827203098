import {
  call,
  delay,
  put,
  takeLatest
} from "redux-saga/effects";
import { PERMISSIONS_REQUEST } from "../../constants/auth/permissionsConstants";

import {
  permissionErrorAction,
  permissionOkAction,
} from "../../actions/auth/permissionsActions";

import { permissionDB } from "../../../config/apiCalls";

export function* permissions(action) {
  const permission = action.permission;

  try {
    const response = yield call(permissionDB, permission);

    if (response.status === 200) {
      yield delay(500);
      yield put(permissionOkAction(response.data));
    }
  } catch (error) {
    yield put(permissionErrorAction(true));
  }
}

export function* watchPermissionAsync() {
  yield takeLatest(PERMISSIONS_REQUEST, permissions);
}
