import { combineReducers } from "redux";
import MenuReducers from "./menuReducers";
import uiReducer from "./uiReducers";
//admin
import userReducers from "./modules/AdminPanel/userReducers";
import userLevelPrivilegesReducer from "./modules/userLevelPrivilegesReducer";
import userLevelsReducer from "./modules/userLevelsReducer";
import userRolesReducers from "./modules/userRolesReducers";

//settings
import LoginReducers from "./auth/authReducers";
import permissionsReducers from "./auth/permissionsReducers";
import accountTypesReducers from "./modules/accountTypesReducers";
import accountsReducers from "./modules/accountsReducers";
import approvalFormSetupReducers from "./modules/approvalFormSetupReducers";
import approvalPanelMembersReducers from "./modules/approvalPanelMembersReducers";
import approvalPanelsReducers from "./modules/approvalPanelsReducers";
import categoriesReducers from "./modules/categoriesReducers";
import companiesReducers from "./modules/companiesReducers";
import loadingPointReducers from "./modules/loadingPointReducers";
import measuringUnitsReducers from "./modules/measuringUnitsReducers";
import productBrandsReducers from "./modules/productBrandsReducers";
import productReducers from "./modules/productReducers";
import productTypeReducers from "./modules/productTypeReducers";
import subCategoriesReducers from "./modules/subCategoriesReducers";
import supplierReducers from "./modules/suppliersReducers";
import userAccessesReducers from "./modules/userAccessReducers";

const rootReducer = combineReducers({
  categoryList: categoriesReducers,
  subCategoryList: subCategoriesReducers,
  productBrandList: productBrandsReducers,
  measuringUnitList: measuringUnitsReducers,
  ui: uiReducer,
  menuList: MenuReducers,
  userList: userReducers,
  loginInfoList: LoginReducers,
  loadingPointList: loadingPointReducers,
  permissionList: permissionsReducers,
  userRoleList: userRolesReducers,
  userAccessList: userAccessesReducers,  
  supplierList: supplierReducers,
  accountList: accountsReducers,
  accountTypeList: accountTypesReducers,  
  productTypeList: productTypeReducers,
  productList: productReducers, 
  companyList: companiesReducers, 
  approvalPanelList: approvalPanelsReducers,
  approvalPanelMemberList: approvalPanelMembersReducers,
  approvalFormSetupList: approvalFormSetupReducers, 
  userLevelList: userLevelsReducer,
  userLevelPrivilegeList: userLevelPrivilegesReducer,
});

export default rootReducer;
