import { toast } from "react-toastify";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import {
  ADD_USERS,
  BEGIN_EDIT_USERS,
  BEGIN_USERS_DOWNLOAD,
  RETRIEVE_USERS_BULK_DELETE,
  RETRIEVE_USERS_DELETE,
} from "../../../constants/modules/AdminPanel/userConstants";

import {
  addUserErrorAction,
  addUserOkAction,
  bulkDeleteUserErrorAction,
  bulkDeleteUserOkAction,
  deleteUserErrorAction,
  deleteUserOkAction,
  downloadUsersErrorAction,
  downloadUsersOkAction,
  editUserErrorAction,
  editUserOkAction,
} from "../../../actions/modules/AdminPanel/usersActions";

import {
  addUserDB,
  bulkDeleteUserDB,
  deleteUserDB,
  editUserDB,
  retrieveUsersData,
} from "../../../../config/apiCalls";

// Retrieve products
// worker saga
export function* retrieveUsers() {
  try {
    const response = yield call(retrieveUsersData);

    if (response.status === 200) {
      yield put(downloadUsersOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadUsersErrorAction());
  }
}

// Create new product
// worker saga
export function* addUser(action) {
  const user = action.user;

  try {
    const response = yield call(addUserDB, user);

    if (response.status === 201) {
      yield delay(500);

      yield put(addUserOkAction(user));
      toast.success("User created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addUserErrorAction(true));
    toast.error("User created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteUser(action) {
  const id = action.payload;
  try {
    yield call(deleteUserDB, id);
    yield deleteUserOkAction();
    toast.success("User deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteUserErrorAction();
  }
}

// Edit product
// worker saga
export function* editUser(action) {
  const user = action.user;

  try {
    const response = yield call(editUserDB, user);
    if (response.status === 201) {
      yield delay(500);
      yield editUserOkAction(user);
      toast.success("User has been updated!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield editUserErrorAction();
  }
}

// BULK SAGAS
export function* bulkUserDelete(action) {
  const user = action.user;

  try {
    const response = yield call(bulkDeleteUserDB, user);
    if (response.status === 200) {
      yield delay(2000);
      yield put(bulkDeleteUserOkAction(user));
      toast.success("Users deleted successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(bulkDeleteUserErrorAction(true));
    toast.error("Users deleted failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

export function* watchUsersAsync() {
  yield takeLatest(BEGIN_USERS_DOWNLOAD, retrieveUsers);
  yield takeLatest(ADD_USERS, addUser);
  yield takeLatest(RETRIEVE_USERS_DELETE, deleteUser);
  yield takeLatest(BEGIN_EDIT_USERS, editUser);
  yield takeLatest(RETRIEVE_USERS_BULK_DELETE, bulkUserDelete);
}
