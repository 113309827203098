import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
// import logo from "./Renaissence@3x.jpeg";
// Create styles
const Quixote = ({ invoiceData, companyInfo, getSummInfo }) => {
  const headerInfo = invoiceData && invoiceData.length && invoiceData[0];
  const tableBodyData = invoiceData && invoiceData.length && invoiceData[0];

  const tableBodyDataRefine =
    tableBodyData &&
    Object.keys(tableBodyData).map((key) => tableBodyData[key]);

  // totalQuantity
  const totalOrderQty =
    tableBodyDataRefine &&
    tableBodyDataRefine.length &&
    tableBodyDataRefine.reduce(
      (totalValue, currentValue) => totalValue + Number(currentValue?.quantity),
      0
    );
  // saleQty
  const totalSaleQty =
    tableBodyDataRefine &&
    tableBodyDataRefine.length &&
    tableBodyDataRefine.reduce(
      (totalValue, currentValue) => totalValue + Number(currentValue?.sale_qty),
      0
    );
  // totalAmount
  const totalAmount =
    tableBodyDataRefine &&
    tableBodyDataRefine.length &&
    tableBodyDataRefine.reduce(
      (totalValue, currentValue) => totalValue + Number(currentValue?.amount),
      0
    );

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}

        <View wrap={false}>
          <View
            style={{
              borderBottom: "3px solid black",
              paddingBottom: 5,
            }}
          >
            <View style={{ margin: "auto" }}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: 900,
                  fontFamily: "Times",
                }}
              >
                {companyInfo?.[0]?.["company_name"]}
              </Text>
            </View>
            <View style={{ margin: "auto" }}>
              <Text style={{ fontSize: 12 }}>
                {companyInfo?.[0]?.["street"]}
                {", "}
                {companyInfo?.[0]?.["city"]}
                {", "}
                {companyInfo?.[0]?.["country"]}
              </Text>
            </View>
          </View>

          <View
            style={{
              height: "auto",
              marginTop: "2px",
            }}
          >
            <Text
              style={{
                fontSize: "13px",
                fontWeight: "bold",
                fontFamily: "Times",
                textAlign: "center",
              }}
            >
              DO Ledger
            </Text>
            <Text
              style={{
                flexDirection: "row",
                justifyContent: "center",
                margin: "auto",
                borderBottom: "1px solid black",
                width: 150,
              }}
            >
              {""}
            </Text>
            <Text
              style={{
                fontSize: "10px",
                textAlign: "center",
                padding: "1px",
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                }}
              >
                From
              </Text>{" "}
              {getSummInfo &&
                new Date(getSummInfo?.["p_from_date"]).toLocaleDateString([
                  "es-CL",
                  "id",
                ])}
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                }}
              >
                {" "}
                To
              </Text>{" "}
              {getSummInfo &&
                new Date(getSummInfo?.["p_to_date"]).toLocaleDateString([
                  "es-CL",
                  "id",
                ])}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <View>
              <Text style={{ fontSize: "9px" }}>
                <Text style={{ fontWeight: 900, fontFamily: "Times" }}>
                  Print Date:
                </Text>{" "}
                {new Date(defaultValue).toLocaleDateString(["es-CL", "id"])}
              </Text>
              <Text style={{ fontSize: "9px" }}>
                <Text style={{ fontWeight: 900, fontFamily: "Times" }}>
                  Print By:
                </Text>{" "}
                {`${getInfo?.first_name ? getInfo?.first_name : ""} ${
                  getInfo?.last_name ? getInfo?.last_name : ""
                }`}
              </Text>
            </View>
          </View>
        </View>

        {/* TABLE PART */}
        <View style={{ marginTop: "20px" }}>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "100%",
              backgroundColor: "#d3cfcf",
            }}
            wrap={false}
            fixed
          >
            <Text
              style={{
                padding: 5,
                width: "10%",
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              DO No
            </Text>

            <Text
              style={{
                padding: 5,
                width: "10%",
                fontSize: "10px",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              DO Date
            </Text>
            <Text
              style={{
                padding: 5,
                fontSize: "10px",
                width: "10%",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Loading Point
            </Text>
            <Text
              style={{
                padding: 5,
                width: "10%",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                fontSize: "10px",
                textAlign: "center",
              }}
            >
              Product Name
            </Text>
            <Text
              style={{
                padding: 5,
                fontSize: "10px",
                width: "10%",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              UOM
            </Text>
            <Text
              style={{
                padding: 5,
                fontSize: "10px",
                width: "10%",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Quantity
            </Text>
            <Text
              style={{
                padding: 5,
                fontSize: "10px",
                width: "10%",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Rate
            </Text>
            <Text
              style={{
                padding: 5,
                fontSize: "10px",
                width: "10%",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Amount
            </Text>
            <Text
              style={{
                padding: 5,
                fontSize: "10px",
                width: "10%",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Sale Qty
            </Text>
            <Text
              style={{
                padding: 5,
                fontSize: "10px",
                width: "10%",
                fontFamily: "Times",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Balance Qty
            </Text>
          </View>
          <View style={{ borderTop: "1px solid black" }}>
            {tableBodyDataRefine &&
              tableBodyDataRefine.length &&
              tableBodyDataRefine.map((item, index) => (
                <View
                  key={index}
                  break={index > 9}
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderBottom: "1px solid black",
                  }}
                >
                  {" "}
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.do_no}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.do_date}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.loding_point}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.product_name}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {item?.uom}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {Math.abs(item?.quantity)}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {Number(item?.rate).toFixed(2)}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {Number(item?.amount).toFixed(2)}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {Math.abs(item?.sale_qty)}
                  </Text>
                  <Text
                    style={{
                      padding: "5px",
                      fontSize: "8px",
                      height: "auto",
                      width: "10%",
                      textAlign: "center",
                    }}
                  >
                    {Math.abs(item?.bal_qty)}
                  </Text>
                </View>
              ))}
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                border: "1px solid black",
              }}
              wrap={false}
            >
              <Text
                style={{
                  padding: "5px",
                  fontSize: "12px",
                  height: "auto",
                  width: "50%",
                  color: "green",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Total
              </Text>

              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "10%",
                  color: "green",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {totalOrderQty}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "10%",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              ></Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "10%",
                  color: "green",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {Number(totalAmount)?.toFixed(2)}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "10%",
                  color: "green",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                {totalSaleQty}
              </Text>
              <Text
                style={{
                  padding: "5px",
                  fontSize: "10px",
                  height: "auto",
                  width: "10%",
                  textAlign: "center",
                }}
              >
                {/* {Number(totalBalanceQty)?.toFixed(2)} */}
              </Text>
            </View>
          </View>
        </View>

        {/* Signature part */}
        {/* <View
          fixed
          style={{
            flexDirection: "row",
            bottom: 0,
            marginTop: 60,
            justifyContent: "space-around",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Received Name
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Customer Seal & Signature
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Authorised Signature
              </Text>
            </View>
          </View>
        </View> */}

        {/* TOTAL CALCULATE PART */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

Font.register({
  family: "Helvetica",
  fonts: [
    {
      src: "/assets/fonts/Helvetica.ttf",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/Helvetica Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Times",
  fonts: [
    {
      src: "/assets/fonts/Times-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
    // marginTop: "-20px",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 60,
    fontFamily: "Helvetica",
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
