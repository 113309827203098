
import axiosClient from "../axiosClient";



/*-------------------------------------------------*/
/*               COMPANIES API CALLS               */
/*-------------------------------------------------*/

export async function bulkDeleteCompanyDB(ids) {
    return await axiosClient.post(`/companies/bulk_delete`, ids);
  }
  
  export async function retrieveCompaniesData() {
    return await axiosClient.get("/companies");
  }
  
  export async function addCompanyDB(value) {

    var bodyFormData = new FormData();


  bodyFormData.append("company_name", value.company_name);
  bodyFormData.append("country", value.country);
  bodyFormData.append("phone_no", value.phone_no);
  bodyFormData.append("email_address", value.email_address);
  bodyFormData.append("city", value.city);
  
  bodyFormData.append("logo", value.logo);
  bodyFormData.append("slogan", value.slogan);
  bodyFormData.append("street", value.street);
  bodyFormData.append("vat_registration_no", value.vat_registration_no);
  bodyFormData.append("zip_code", value.zip_code);
  bodyFormData.append("created_by", value.created_by);
  bodyFormData.append("created_date", value.created_date);
  bodyFormData.append("modified_by", value.modified_by);
  bodyFormData.append("modified_date", value.modified_date);
  
    return await axiosClient.post("/companies", bodyFormData);
  }
  
  export async function deleteCompanyDB(id) {
    return await axiosClient.delete(`/companies/${id}`);
  }
  
  export async function editCompanyDB(value) {
    var bodyFormData = new FormData();
    
    bodyFormData.append("company_name", value.company_name);
    bodyFormData.append("country", value.country);
    bodyFormData.append("phone_no", value.phone_no);
    bodyFormData.append("email_address", value.email_address);
    bodyFormData.append("city", value.city);
    
    bodyFormData.append("logo", value.logo);
    bodyFormData.append("slogan", value.slogan);
    bodyFormData.append("street", value.street);
    bodyFormData.append("vat_registration_no", value.vat_registration_no);
    bodyFormData.append("zip_code", value.zip_code);
    bodyFormData.append("created_by", value.created_by);
    bodyFormData.append("created_date", value.created_date);
    bodyFormData.append("modified_by", value.modified_by);
    bodyFormData.append("modified_date", value.modified_date);
  
    
    return await axiosClient.put(`/companies/${value.id}`, value);
  }