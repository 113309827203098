// Export all sagas
import { all } from "redux-saga/effects";
import { watchUsersAsync } from "../sagas/modules/AdminPanel/usersSagas";
import { watchLoginAsync } from "./auth/authSagas";
import { watchPermissionAsync } from "./auth/permissionsSagas";
import { watchModulesAsync } from "./menuSagas";
import { watchApprovalPanelMembersAsync } from "./modules/approvalPanelMembersSaga";
import { watchApprovalPanelsAsync } from "./modules/approvalPanelsSaga";
import { watchCompaniesAsync } from "./modules/companiesSagas";
import { watchProductsAsync } from "./modules/productSagas";
import { watchProductTypesAsync } from "./modules/productTypeSagas";
import { watchSuppliersAsync } from "./modules/suppliersSagas";
import { watchUserAccessesAsync } from "./modules/userAccessSagas";
import { watchUserRolesAsync } from "./modules/userRolesSagas";



import { watchApprovalFormSetupsAsync } from "./modules/approvalFormSetupSaga";

import { watchUserLevelPrivilegesAsync } from "./modules/userLevelPrivilegesSaga";
import { watchUserLevelsAsync } from "./modules/userLevelsSaga";

import { watchAccountTypesAsync } from "./modules/accountTypesSagas";
import { watchAccountsAsync } from "./modules/accountsSagas";
import { watchCategoriesAsync } from "./modules/categoriesSagas";
import { watchLoadingPointsAsync } from "./modules/loadingPointSagas";
import { watchMeasuringUnitsAsync } from "./modules/measuringUnitsSagas";
import { watchProductBrandsAsync } from "./modules/productBrandsSagas";
import { watchSubCategoriesAsync } from "./modules/subCategoriesSagas";

export default function* rootSaga() {
  yield all([
    watchCategoriesAsync(),
    watchSubCategoriesAsync(),
    watchMeasuringUnitsAsync(),
    watchUsersAsync(),
    watchModulesAsync(),
    watchSuppliersAsync(),
    watchAccountsAsync(),
    watchLoadingPointsAsync(),
    watchAccountTypesAsync(),   
    watchProductTypesAsync(),
    watchProductsAsync(),
    watchCompaniesAsync(),
    watchUserRolesAsync(),
    watchUserAccessesAsync(),
    watchLoginAsync(),
    watchPermissionAsync(),
    watchApprovalPanelMembersAsync(),
    watchApprovalPanelsAsync(),
    watchApprovalFormSetupsAsync(),
    watchUserLevelPrivilegesAsync(),
    watchUserLevelsAsync(),
    watchProductBrandsAsync(),
  ]);
}
