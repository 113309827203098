import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
// Create styles
const Quixote = ({ invoiceData, companyInfo, getInfo, getSummInfo }) => {
  const reportData = invoiceData && invoiceData.length ? invoiceData[0] : [];

  const refineTableData = reportData
    ? Object.keys(reportData).map((key) => reportData[key])
    : [];

  let grandtotalOrderQty = 0;
  let grandtotalReceivedAmt = 0;
  let grandtotalSalesAmt = 0;
  let grandtotalBalanceAmt = 0;

  let count = 0;

  const totalQty =
    refineTableData && refineTableData.length
      ? refineTableData.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.quantity),
          0
        )
      : 0;

  const totalAmt =
    refineTableData && refineTableData.length
      ? refineTableData.reduce(
          (totalValue, currentValue) =>
            totalValue + Number(currentValue?.amount),
          0
        )
      : 0;

  const BuyerData = ({ i2 }) => {
    return (
      <View
        wrap={false}
        break={count > 13}
        style={{
          borderBottom: "1px solid black",
          borderLeft: "1px solid black",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Text
          style={{
            padding: "2px",
            fontSize: "7px",
            height: "auto",
            width: "10%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.pur_date}
        </Text>
        <Text
          style={{
            padding: "2px",
            fontSize: "7px",
            height: "auto",
            width: "15%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.purchase_no}
        </Text>
        <Text
          style={{
            padding: "2px",
            fontSize: "7px",
            height: "auto",
            width: "19.95%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.supplier_name}
        </Text>
        <Text
          style={{
            padding: "2px",
            fontSize: "7px",
            height: "auto",
            width: "16.95%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.product_name}
        </Text>
        <Text
          style={{
            padding: "2px",
            fontSize: "7px",
            height: "auto",
            width: "9%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.uom}
        </Text>
        <Text
          style={{
            padding: "2px",
            fontSize: "7px",
            height: "auto",
            width: "8.99%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {Math.abs(i2?.quantity ? i2?.quantity : 0)}
        </Text>
        <Text
          style={{
            padding: "2px",
            fontSize: "7px",
            height: "auto",
            width: "8.99%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {Number(i2?.rate ? i2?.rate : 0).toFixed(2)}
        </Text>
        <Text
          style={{
            padding: "2px",
            fontSize: "7px",
            height: "auto",
            width: "11.1%",
            borderRight: "1px solid black",
            textAlign: "right",
          }}
        >
          {Number(i2.amount ? i2.amount : 0).toFixed(2)}
        </Text>
      </View>
    );
  };

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  return (
    <Document>
      <Page orientation="portrait" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}

        <View>
          <View wrap={false}>
            <View
              style={{
                borderBottom: "3px solid black",
                paddingBottom: 5,
              }}
            >
              <View style={{ margin: "auto" }}>
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: 900,
                    fontFamily: "Times",
                  }}
                >
                  {companyInfo?.[0]?.["company_name"]}
                </Text>
              </View>
              <View style={{ margin: "auto" }}>
                <Text style={{ fontSize: 12 }}>
                  {companyInfo?.[0]?.["street"]}
                  {", "}
                  {companyInfo?.[0]?.["city"]}
                  {", "}
                  {companyInfo?.[0]?.["country"]}
                </Text>
              </View>
            </View>

            <View
              style={{
                height: "auto",
                marginTop: "2px",
              }}
            >
              <Text
                style={{
                  fontSize: "13px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                  textAlign: "center",
                }}
              >
                Purchase Register
              </Text>
              <Text
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  margin: "auto",
                  borderBottom: "1px solid black",
                  width: 150,
                }}
              >
                {""}
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  textAlign: "center",
                  padding: "1px",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    fontFamily: "Times",
                  }}
                >
                  From
                </Text>{" "}
                {getSummInfo &&
                  new Date(getSummInfo?.["p_from_date"]).toLocaleDateString([
                    "es-CL",
                    "id",
                  ])}
                <Text
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    fontFamily: "Times",
                  }}
                >
                  {" "}
                  To
                </Text>{" "}
                {getSummInfo &&
                  new Date(getSummInfo?.["p_to_date"]).toLocaleDateString([
                    "es-CL",
                    "id",
                  ])}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <View>
                <Text style={{ fontSize: "9px" }}>
                  <Text style={{ fontWeight: 900, fontFamily: "Times" }}>
                    Print Date:
                  </Text>{" "}
                  {new Date(defaultValue).toLocaleDateString(["es-CL", "id"])}
                </Text>
                <Text style={{ fontSize: "9px" }}>
                  <Text style={{ fontWeight: 900, fontFamily: "Times" }}>
                    Print By:
                  </Text>{" "}
                  {`${getInfo?.first_name ? getInfo?.first_name : ""} ${
                    getInfo?.last_name ? getInfo?.last_name : ""
                  }`}
                </Text>
              </View>
            </View>
          </View>
          {/* TABLE PART */}

          <View style={{ marginTop: "20px" }}>
            <View
              style={{
                border: "1px solid black",
                flexDirection: "row",
                width: "100%",
                backgroundColor: "#d3cfcf",
              }}
              fixed
            >
              <Text
                style={{
                  padding: "2px",
                  width: "10%",
                  fontSize: "9px",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Date
              </Text>
              <Text
                style={{
                  padding: "2px",
                  width: "15%",
                  fontSize: "9px",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Purchase No.
              </Text>

              <Text
                style={{
                  padding: "2px",
                  width: "20%",
                  fontSize: "9px",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Supplier Name
              </Text>

              <Text
                style={{
                  padding: "2px",
                  fontSize: "9px",
                  width: "17%",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  alignItems: "center",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Item
              </Text>
              <Text
                style={{
                  padding: "2px",
                  width: "9%",
                  borderRight: "1px solid black",
                  fontSize: "9px",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Uom
              </Text>
              <Text
                style={{
                  padding: "2px",
                  width: "9%",
                  fontSize: "9px",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Quantity
              </Text>
              <Text
                style={{
                  padding: "2px",
                  fontSize: "9px",
                  width: "9%",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}
              >
                Rate
              </Text>
              <Text
                style={{
                  padding: "2px",
                  fontSize: "9px",
                  width: "11%",
                  fontFamily: "Times",
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >
                Amount
              </Text>
            </View>

            <View>
              <View
                style={{
                  width: "100%",
                }}
              >
                {refineTableData && refineTableData?.length
                  ? refineTableData?.map((i2, i) => {
                      count = count + 1;
                      return (
                        <View key={i}>
                          <BuyerData key={i} i2={i2} />
                        </View>
                      );
                    })
                  : null}
                <View
                  style={{
                    borderBottom: "1px solid black",
                    borderLeft: "1px solid black",
                    flexDirection: "row",
                    width: "100%",
                  }}
                  wrap={false}
                >
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "9px",
                      height: "auto",
                      width: "10%",
                      color: "green",
                      fontFamily: "Times",
                      fontWeight: "bold",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {"Total"}
                  </Text>
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "8px",
                      height: "auto",
                      width: "15%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "8px",
                      height: "auto",
                      width: "19.95%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "8px",
                      height: "auto",
                      width: "16.99%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "8px",
                      height: "auto",
                      width: "8.95%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "8px",
                      height: "auto",
                      width: "8.99%",
                      color: "green",
                      fontFamily: "Times",
                      fontWeight: "bold",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {Number(totalQty).toFixed(2)}
                  </Text>
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "8px",
                      height: "auto",
                      width: "9%",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {""}
                  </Text>
                  <Text
                    style={{
                      padding: "2px",
                      fontSize: "9px",
                      height: "auto",
                      width: "11.1%",
                      color: "green",
                      fontFamily: "Times",
                      fontWeight: "bold",
                      borderRight: "1px solid black",
                      textAlign: "right",
                    }}
                  >
                    {Number(totalAmt).toFixed(2)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        {/* Signature part */}
        {/* <View
          fixed
          style={{
            flexDirection: "row",
            bottom: 0,
            marginTop: 60,
            justifyContent: "space-around",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Received Name
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Customer Seal & Signature
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Authorised Signature
              </Text>
            </View>
          </View>
        </View> */}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

Font.registerHyphenationCallback((word) => {
  if (word.length > 16) {
    return chunkSubstr(word, 14);
  } else {
    return [word];
  }
});

Font.register({
  family: "Helvetica",
  fonts: [
    {
      src: "/assets/fonts/Helvetica.ttf",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/Helvetica Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Times",
  fonts: [
    {
      src: "/assets/fonts/Times-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  body: {
    paddingTop: 25,
    paddingBottom: 60,
    paddingHorizontal: 20,
    fontFamily: "Helvetica",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
