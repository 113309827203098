import axiosClient from "../axiosClient";


/*-------------------------------------------------*/
/*               SUB-CATEGORIES API CALLS               */
/*-------------------------------------------------*/

export async function retrieveSubCategoriesData() {
    return await axiosClient.get("/sub_categories");
  }

export async function addSubCategoryDB(value) {
    var bodyFormData = new FormData();
    bodyFormData.append("sub_category_name", value.sub_category_name);
    bodyFormData.append("category_id", value.category_id);
    bodyFormData.append("description", value.description);
    bodyFormData.append("active_status", value.active_status);
    bodyFormData.append("logo", value.logo);
    bodyFormData.append("print_serial", value.print_serial);
    bodyFormData.append("created_by", value.created_by);
    bodyFormData.append("created_date", value.created_date);
    bodyFormData.append("modified_by", value.modified_by);
    bodyFormData.append("modified_date", value.modified_date);
    
  
    return await axiosClient.post("/sub_categories", bodyFormData);
  }
  
  export async function deleteSubCategoryDB(id) {
    return await axiosClient.delete(`/sub_categories/${id}`);
  }
  
  export async function bulkDeleteSubCategoryDB(ids) {
    return await axiosClient.post(`/sub_categories/bulk_delete`, ids);
  }
  
  export async function editSubCategoryDB(value) {
    var bodyFormData = new FormData();
  
    bodyFormData.append("sub_category_name", value.sub_category_name);
    bodyFormData.append("category_id", value.category_id);
    bodyFormData.append("description", value.description);
    bodyFormData.append("active_status", value.active_status);
    bodyFormData.append("logo", value.logo);
    bodyFormData.append("print_serial", value.print_serial);
    bodyFormData.append("created_by", value.created_by);
    bodyFormData.append("created_date", value.created_date);
    bodyFormData.append("modified_by", value.modified_by);
    bodyFormData.append("modified_date", value.modified_date);
    
  
    return await axiosClient.put(`/sub_categories/${value.id}`, bodyFormData);
  }
  