import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
// Create styles
const Quixote = ({ invoiceData, companyInfo, getInfo, getSummInfo }) => {
  const reportData = invoiceData && invoiceData.length && invoiceData;

  let grandtotalOrderQty = 0;
  let grandtotalReceivedAmt = 0;
  let grandtotalSalesAmt = 0;
  let grandtotalBalanceAmt = 0;

  let count = 0;

  const BuyerData = ({ i2 }) => {
    return (
      <View
        wrap={false}
        break={count > 13}
        style={{
          borderBottom: "1px solid black",
          borderLeft: "1px solid black",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "12.5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.transaction_date}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "12.5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.delivery_date}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "12.5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.doc_no}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "12.5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.transport_no}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "12.5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.created_by}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "12.5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.purticulars}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "12.5%",
            borderRight: "1px solid black",
            textAlign: "center",
          }}
        >
          {i2?.product_name}
        </Text>
        <Text
          style={{
            padding: "5px",
            fontSize: "8px",
            height: "auto",
            width: "12.5%",
            textAlign: "center",
          }}
        >
          {Math.round(Number(i2?.quantity))}
        </Text>
      </View>
    );
  };

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  return (
    <Document>
      <Page orientation="portrait" size="A4" style={styles.body} wrap>
        {/* Invoice Image & Header PART */}
        <View wrap={false}>
          <View
            style={{
              borderBottom: "3px solid black",
              paddingBottom: 5,
            }}
          >
            <View style={{ margin: "auto" }}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: 900,
                  fontFamily: "Times",
                }}
              >
                {companyInfo?.[0]?.["company_name"]}
              </Text>
            </View>
            <View style={{ margin: "auto" }}>
              <Text style={{ fontSize: 12 }}>
                {companyInfo?.[0]?.["street"]}
                {", "}
                {companyInfo?.[0]?.["city"]}
                {", "}
                {companyInfo?.[0]?.["country"]}
              </Text>
            </View>
          </View>

          <View
            style={{
              height: "auto",
              marginTop: "2px",
            }}
          >
            <Text
              style={{
                fontSize: "13px",
                fontWeight: "bold",
                fontFamily: "Times",
                textAlign: "center",
              }}
            >
              Item Stock Ledger
            </Text>
            <Text
              style={{
                flexDirection: "row",
                justifyContent: "center",
                margin: "auto",
                borderBottom: "1px solid black",
                width: 150,
              }}
            >
              {""}
            </Text>
            <Text
              style={{
                fontSize: "10px",
                textAlign: "center",
                padding: "1px",
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                }}
              >
                From
              </Text>{" "}
              {getSummInfo &&
                new Date(getSummInfo?.["p_from_date"]).toLocaleDateString([
                  "es-CL",
                  "id",
                ])}
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                }}
              >
                {" "}
                To
              </Text>{" "}
              {getSummInfo &&
                new Date(getSummInfo?.["p_to_dat"]).toLocaleDateString([
                  "es-CL",
                  "id",
                ])}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <View>
              <Text style={{ fontSize: "9px" }}>
                <Text style={{ fontWeight: 900, fontFamily: "Times" }}>
                  Print Date:
                </Text>{" "}
                {new Date(defaultValue).toLocaleDateString(["es-CL", "id"])}
              </Text>
              <Text style={{ fontSize: "9px" }}>
                <Text style={{ fontWeight: 900, fontFamily: "Times" }}>
                  Print By:
                </Text>{" "}
                {`${getInfo?.first_name ? getInfo?.first_name : ""} ${
                  getInfo?.last_name ? getInfo?.last_name : ""
                }`}
              </Text>
            </View>
          </View>
        </View>

        {reportData &&
          reportData?.map((customer, index) => {
            const filtering =
              customer &&
              customer?.data?.filter(
                (item) => item?.purticulars?.toLowerCase() === "opening"
              );

            const newArr =
              customer &&
              customer?.data?.filter(
                (item) => item?.purticulars?.toLowerCase() !== "opening"
              );

            const subtotalOrderQty =
              customer?.data && customer?.data.length
                ? customer?.data.reduce(
                    (totalQty, currentQty) =>
                      totalQty + Number(currentQty?.quantity),
                    0
                  )
                : 0;

            grandtotalOrderQty += subtotalOrderQty;

            const subtotalReceivedAmt =
              customer?.data && customer?.data.length
                ? customer?.data.reduce(
                    (totalValue, currentValue) =>
                      totalValue + Number(currentValue?.receive_amt),
                    0
                  )
                : null;

            grandtotalReceivedAmt += subtotalReceivedAmt;

            const subtotalSalesAmt =
              customer?.data && customer?.data.length
                ? customer?.data.reduce(
                    (totalValue, currentValue) =>
                      totalValue + Number(currentValue?.sales_amt),
                    0
                  )
                : null;

            grandtotalSalesAmt += subtotalSalesAmt;

            const subtotalBalanceAmt =
              customer?.data && customer?.data.length
                ? customer?.data.reduce(
                    (totalValue, currentValue) =>
                      totalValue + Number(currentValue?.balance_amt),
                    0
                  )
                : null;
            grandtotalBalanceAmt += subtotalBalanceAmt;

            return (
              <View key={index} break={index > 0}>
                <View style={{ marginTop: "20px" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 5,
                      backgroundColor: "#ffe7bf",
                    }}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={{
                          padding: "3px",
                          // width: "12.5%",
                          fontSize: "10px",
                          fontWeight: "bold",
                          fontFamily: "Times",
                        }}
                      >
                        Item Group:{" "}
                      </Text>
                      <Text
                        style={{
                          padding: "3px",
                          // width: "12.5%",
                          fontSize: "10px",
                          fontWeight: "bold",
                          fontFamily: "Times",
                        }}
                      >
                        Stationary & Suppliers
                      </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={{
                          padding: "2px",
                          // width: "12.5%",
                          fontSize: "9px",
                          fontWeight: "bold",
                          fontFamily: "Times",
                        }}
                      >
                        Item Code:
                      </Text>
                      <Text
                        style={{
                          padding: "2px",
                          // width: "12.5%",
                          fontSize: "9px",
                          fontWeight: "bold",
                          fontFamily: "Times",
                        }}
                      >
                        496
                      </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={{
                          padding: "2px",
                          // width: "12.5%",
                          fontSize: "9px",
                          fontWeight: "bold",
                          fontFamily: "Times",
                        }}
                      >
                        Item Name:{" "}
                      </Text>
                      <Text
                        style={{
                          padding: "2px",
                          // width: "12.5%",
                          fontSize: "9px",
                          fontWeight: "bold",
                          fontFamily: "Times",
                        }}
                      >
                        Fluid Pea
                      </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={{
                          padding: "2px",
                          // width: "12.5%",
                          fontSize: "9px",
                          fontWeight: "bold",
                          fontFamily: "Times",
                        }}
                      >
                        UOM:{" "}
                      </Text>
                      <Text
                        style={{
                          padding: "2px",
                          // width: "12.5%",
                          fontSize: "9px",
                          fontWeight: "bold",
                          fontFamily: "Times",
                        }}
                      >
                        Pcs
                      </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <Text
                        style={{
                          padding: "2px",
                          // width: "12.5%",
                          fontSize: "9px",
                          fontWeight: "bold",
                          fontFamily: "Times",
                        }}
                      >
                        Opening:{" "}
                      </Text>
                      <Text
                        style={{
                          padding: "2px",
                          // width: "12.5%",
                          fontSize: "9px",
                          fontWeight: "bold",
                          fontFamily: "Times",
                        }}
                      >
                        0
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      border: "1px solid black",
                      flexDirection: "row",
                      width: "100%",
                      backgroundColor: "#d3cfcf",
                    }}
                    fixed
                  >
                    <Text
                      style={{
                        padding: "2px",
                        width: "12.5%",
                        fontSize: "9px",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Date
                    </Text>
                    <Text
                      style={{
                        padding: "2px",
                        width: "12.5%",
                        fontSize: "9px",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Document No
                    </Text>

                    <Text
                      style={{
                        padding: "2px",
                        width: "12.5%",
                        fontSize: "9px",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Challan/LC No
                    </Text>
                    <Text
                      style={{
                        padding: "2px",
                        fontSize: "9px",
                        width: "12.5%",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        alignItems: "center",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Receive Qty
                    </Text>
                    <Text
                      style={{
                        padding: "2px",
                        fontSize: "9px",
                        width: "12.5%",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        alignItems: "center",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Rate
                    </Text>
                    <Text
                      style={{
                        padding: "2px",
                        width: "12.5%",
                        borderRight: "1px solid black",
                        fontSize: "9px",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        textAlign: "center",
                      }}
                    >
                      Currency
                    </Text>
                    <Text
                      style={{
                        padding: "2px",
                        width: "12.5%",
                        fontSize: "9px",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        borderRight: "1px solid black",
                        textAlign: "center",
                      }}
                    >
                      Issue Qty
                    </Text>
                    <Text
                      style={{
                        padding: "2px",
                        fontSize: "9px",
                        width: "12.5%",
                        fontWeight: "bold",
                        fontFamily: "Times",
                        textAlign: "center",
                      }}
                    >
                      Balance Qty
                    </Text>
                  </View>

                  <View>
                    <View
                      style={{
                        // flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      {/* <View
                        wrap={false}
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                          border: "1px solid black",
                          height: "auto",
                        }}
                      >
                        <Text
                          style={{
                            padding: "5px 0 5px 10px",
                            fontSize: "11px",
                            fontWeight: "bold",fontFamily: "Times",
                            color: "black",
                            height: "auto",
                          }}
                        >
                          {filtering?.[0]?.["purticulars"]}
                        </Text>
                        <Text
                          style={{
                            padding: "5px 2px 5px 0",
                            fontSize: "11px",
                            fontWeight: "bold",fontFamily: "Times",
                            color: "black",
                            height: "auto",
                          }}
                        >
                          {filtering?.[0]?.["cumulative_sum"]
                            ? filtering?.[0]?.["cumulative_sum"]
                            : "0.00"}
                        </Text>
                      </View> */}

                      {newArr && newArr?.length
                        ? newArr?.map((i2, i) => {
                            count = count + 1;
                            return (
                              <View key={i}>
                                <BuyerData key={i} i2={i2} />
                              </View>
                            );
                          })
                        : null}
                      <View
                        style={{
                          borderBottom: "1px solid black",
                          borderLeft: "1px solid black",
                          flexDirection: "row",
                          width: "100%",
                        }}
                        wrap={false}
                      >
                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "10px",
                            height: "auto",
                            width: "37.45%",
                            color: "red",
                            fontWeight: "bold",
                            fontFamily: "Times",
                            borderRight: "1px solid black",
                            textAlign: "left",
                          }}
                        >
                          {"Total"}
                        </Text>
                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "8px",
                            height: "auto",
                            width: "12.5%",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {""}
                        </Text>

                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "8px",
                            height: "auto",
                            width: "12.5%",
                            color: "red",
                            fontWeight: "bold",
                            fontFamily: "Times",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {subtotalOrderQty}
                        </Text>

                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "8px",
                            height: "auto",
                            width: "12.5%",
                            color: "red",
                            fontWeight: "bold",
                            fontFamily: "Times",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {Math.abs(Number(subtotalReceivedAmt)).toFixed(2)}
                        </Text>
                        <Text
                          style={{
                            padding: "5px",
                            fontSize: "8px",
                            height: "auto",
                            width: "12.5%",
                            color: "red",
                            fontWeight: "bold",
                            fontFamily: "Times",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {subtotalSalesAmt?.toFixed(2)}
                        </Text>
                        <Text
                          style={{
                            padding: "5px 2px 5px 0",
                            fontSize: "8px",
                            height: "auto",
                            width: "12.5%",
                            color: "red",
                            fontWeight: "bold",
                            fontFamily: "Times",
                            borderRight: "1px solid black",
                            textAlign: "right",
                          }}
                        >
                          {subtotalBalanceAmt?.toFixed(2)}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            );
          })}

        {/* <View
          wrap={false}
          style={{
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            borderTop: "1px solid black",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Text
            style={{
              padding: "5px",
              fontSize: "10px",
              height: "auto",
              width: "8%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {"Grand Total"}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "7%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "7%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "7%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "7%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "10%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          ></Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "10%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "6%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {grandtotalOrderQty}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "6%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "6%",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "8%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {Math.abs(Number(grandtotalReceivedAmt)).toFixed(2)}
          </Text>
          <Text
            style={{
              padding: "5px",
              fontSize: "8px",
              height: "auto",
              width: "8%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {grandtotalSalesAmt?.toFixed(2)}
          </Text>
          <Text
            style={{
              padding: "5px 2px 5px 0",
              fontSize: "8px",
              height: "auto",
              width: "10%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "right",
            }}
          >
            {grandtotalBalanceAmt?.toFixed(2)}
          </Text>
        </View> */}

        {/* Signature part */}
        {/* <View
          fixed
          style={{
            flexDirection: "row",
            bottom: 0,
            marginTop: 60,
            justifyContent: "space-around",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Prepared By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Checked By
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Approved By
              </Text>
            </View>
          </View>
        </View> */}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

Font.registerHyphenationCallback((word) => {
  if (word.length > 16) {
    return chunkSubstr(word, 14);
  } else {
    return [word];
  }
});

Font.register({
  family: "Helvetica",
  fonts: [
    {
      src: "/assets/fonts/Helvetica.ttf",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/Helvetica Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Times",
  fonts: [
    {
      src: "/assets/fonts/Times-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  body: {
    paddingTop: 25,
    paddingBottom: 60,
    paddingHorizontal: 20,
    minWidth: "110vw",
    fontFamily: "Helvetica",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
