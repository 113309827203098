import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
// Create styles
const Quixote = ({ invoiceData, companyInfo, getInfo, getSummInfo }) => {
  const reportData = invoiceData ? invoiceData : {};

  const refineTableData = reportData
    ? Object.keys(reportData).map((key) => reportData[key])
    : [];

  let totalOpeningQty = refineTableData?.reduce(
    (prev, curr) => prev + Number(curr?.op_qty ? curr.op_qty : 0),
    0
  );
  let totalOpeningAmt = refineTableData?.reduce(
    (prev, curr) => prev + Number(curr?.op_amt ? curr.op_amt : 0),
    0
  );
  let totalReceiveQty = refineTableData?.reduce(
    (prev, curr) => prev + Number(curr?.rec_qty ? curr.rec_qty : 0),
    0
  );
  let totalReceiveAmt = refineTableData?.reduce(
    (prev, curr) => prev + Number(curr?.rec_amt ? curr.rec_amt : 0),
    0
  );
  let totalIssueQty = refineTableData?.reduce(
    (prev, curr) => prev + Number(curr?.sale_qty ? curr.sale_qty : 0),
    0
  );
  let totalIssueAmt = refineTableData?.reduce(
    (prev, curr) => prev + Number(curr?.sales_amt ? curr.sales_amt : 0),
    0
  );
  let totalClosingQty = refineTableData?.reduce(
    (prev, curr) => prev + Number(curr?.cl_qty ? curr.cl_qty : 0),
    0
  );
  let totalClosingAmt = refineTableData?.reduce(
    (prev, curr) => prev + Number(curr?.cl_amt ? curr.cl_amt : 0),
    0
  );

  const today = new Date();
  const defaultValue = new Date(today).toISOString().split("T")[0]; // yyyy/mm/dd

  let sl_no = 0;

  return (
    <Document>
      <Page orientation="portrait" size="A4" style={styles.body} wrap>
        <View wrap={false}>
          <View
            style={{
              borderBottom: "3px solid black",
              paddingBottom: 5,
            }}
          >
            <View style={{ margin: "auto" }}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: 900,
                  fontFamily: "Times",
                }}
              >
                {companyInfo?.[0]?.["company_name"]}
              </Text>
            </View>
            <View style={{ margin: "auto" }}>
              <Text style={{ fontSize: 12 }}>
                {companyInfo?.[0]?.["street"]}
                {", "}
                {companyInfo?.[0]?.["city"]}
                {", "}
                {companyInfo?.[0]?.["country"]}
              </Text>
            </View>
          </View>

          <View
            style={{
              height: "auto",
              marginTop: "2px",
            }}
          >
            <Text
              style={{
                fontSize: "13px",
                fontWeight: "bold",
                fontFamily: "Times",
                textAlign: "center",
              }}
            >
              ITEM STOCK SUMMARY
            </Text>
            <Text
              style={{
                flexDirection: "row",
                justifyContent: "center",
                margin: "auto",
                borderBottom: "1px solid black",
                width: 155,
              }}
            >
              {""}
            </Text>
            <Text
              style={{
                fontSize: "10px",
                textAlign: "center",
                padding: "1px",
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                }}
              >
                From
              </Text>{" "}
              {getSummInfo &&
                new Date(getSummInfo?.["p_from_date"]).toLocaleDateString([
                  "es-CL",
                  "id",
                ])}
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fontFamily: "Times",
                }}
              >
                {" "}
                To
              </Text>{" "}
              {getSummInfo &&
                new Date(getSummInfo?.["p_to_date"]).toLocaleDateString([
                  "es-CL",
                  "id",
                ])}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <View>
              <Text style={{ fontSize: "9px" }}>
                <Text style={{ fontWeight: 900, fontFamily: "Times" }}>
                  Print Date:
                </Text>{" "}
                {new Date(defaultValue).toLocaleDateString(["es-CL", "id"])}
              </Text>
              <Text style={{ fontSize: "9px" }}>
                <Text style={{ fontWeight: 900, fontFamily: "Times" }}>
                  Print By:
                </Text>{" "}
                {`${getInfo?.first_name ? getInfo?.first_name : ""} ${
                  getInfo?.last_name ? getInfo?.last_name : ""
                }`}
              </Text>
            </View>
          </View>
        </View>
        {/* TABLE PART */}

        <View style={{ marginTop: 30 }}>
          <View
            style={{
              border: "1px solid black",
              flexDirection: "row",
              width: "100%",
              backgroundColor: "#d3cfcf",
            }}
            fixed
          >
            <Text
              style={{
                paddingTop: "7px",
                width: "5%",
                fontSize: "9px",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Sl No.
            </Text>

            <Text
              style={{
                paddingTop: "7px",
                width: "20%",
                fontSize: "9px",
                fontFamily: "Times",
                fontWeight: "bold",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Item Name
            </Text>

            <Text
              style={{
                paddingTop: "7px",
                width: "7%",
                fontSize: "9px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              UOM
            </Text>
            <Text
              style={{
                padding: "2px",
                width: "8%",
                fontSize: "9px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Opening {"\n"} Qty
            </Text>
            <Text
              style={{
                padding: "1px",
                width: "10%",
                fontSize: "9px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "right",
              }}
            >
              Opening {"\n"} Amt
            </Text>
            <Text
              style={{
                padding: "2px",
                width: "8%",
                fontSize: "9px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Purchase Qty
            </Text>
            <Text
              style={{
                padding: "2px",
                width: "10%",
                fontSize: "9px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "right",
              }}
            >
              Purchase {"\n"} Amt
            </Text>
            <Text
              style={{
                padding: "2px",
                width: "8%",
                fontSize: "9px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Sale {"\n"} Qty
            </Text>
            <Text
              style={{
                padding: "2px",
                width: "10%",
                fontSize: "9px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "right",
              }}
            >
              Sale {"\n"} Amt
            </Text>
            <Text
              style={{
                padding: "2px",
                width: "9%",
                fontSize: "9px",
                fontWeight: "bold",
                fontFamily: "Times",
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              Closing Qty
            </Text>
            <Text
              style={{
                padding: "2px",
                fontSize: "9px",
                width: "10%",
                fontWeight: "bold",
                fontFamily: "Times",
                alignItems: "center",
                textAlign: "right",
              }}
            >
              Closing {"\n"} Amt
            </Text>
          </View>
          <View
            style={{
              width: "100%",
            }}
          >
            {refineTableData && refineTableData?.length
              ? refineTableData?.map((i2, i) => {
                  sl_no = sl_no + 1;
                  return (
                    <View
                      key={i}
                      wrap={false}
                      // break={i > 13}
                      style={{
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          padding: "2px",
                          fontSize: "7px",
                          height: "auto",
                          width: "5%",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {sl_no}
                      </Text>
                      <Text
                        style={{
                          padding: "2px",
                          fontSize: "7px",
                          height: "auto",
                          width: "20%",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {i2?.product_name}
                      </Text>
                      <Text
                        style={{
                          padding: "2px",
                          fontSize: "7px",
                          height: "auto",
                          width: "7%",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {i2?.uom}
                      </Text>
                      <Text
                        style={{
                          padding: "2px",
                          fontSize: "7px",
                          height: "auto",
                          width: "8%",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {Math.abs(i2?.op_qty ? i2?.op_qty : 0)}
                      </Text>
                      <Text
                        style={{
                          padding: "2px",
                          fontSize: "7px",
                          height: "auto",
                          width: "10%",
                          borderRight: "1px solid black",
                          textAlign: "right",
                        }}
                      >
                        {Number(i2?.op_amt ? i2?.op_amt : 0).toFixed(2)}
                      </Text>
                      <Text
                        style={{
                          padding: "2px",
                          fontSize: "7px",
                          height: "auto",
                          width: "8%",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {Math.abs(i2?.rec_qty ? i2?.rec_qty : 0)}
                      </Text>
                      <Text
                        style={{
                          padding: "2px",
                          fontSize: "7px",
                          height: "auto",
                          width: "10%",
                          borderRight: "1px solid black",
                          textAlign: "right",
                        }}
                      >
                        {Number(i2?.rec_amt ? i2?.rec_amt : 0).toFixed(2)}
                      </Text>
                      <Text
                        style={{
                          padding: "2px",
                          fontSize: "7px",
                          height: "auto",
                          width: "8%",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {Math.abs(i2?.sale_qty ? i2?.sale_qty : 0)}
                      </Text>
                      <Text
                        style={{
                          padding: "2px",
                          fontSize: "7px",
                          height: "auto",
                          width: "10%",
                          borderRight: "1px solid black",
                          textAlign: "right",
                        }}
                      >
                        {Number(i2?.sales_amt ? i2?.sales_amt : 0).toFixed(2)}
                      </Text>
                      <Text
                        style={{
                          padding: "2px",
                          fontSize: "7px",
                          height: "auto",
                          width: "9%",
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {Math.abs(i2?.cl_qty ? i2?.cl_qty : 0)}
                      </Text>
                      <Text
                        style={{
                          padding: "2px",
                          fontSize: "7px",
                          height: "auto",
                          width: "10%",
                          textAlign: "right",
                        }}
                      >
                        {Number(i2?.cl_amt ? i2?.cl_amt : 0).toFixed(2)}
                      </Text>
                    </View>
                  );
                })
              : null}
          </View>
        </View>

        <View
          wrap={false}
          style={{
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            borderTop: "0.5px solid black",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Text
            style={{
              padding: "2px",
              fontSize: "9px",
              height: "auto",
              width: "5%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          ></Text>
          <Text
            style={{
              padding: "2px",
              fontSize: "9px",
              height: "auto",
              width: "20%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {"Total"}
          </Text>
          <Text
            style={{
              padding: "2px",
              fontSize: "8px",
              height: "auto",
              width: "7%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {""}
          </Text>

          <Text
            style={{
              padding: "2px",
              fontSize: "8px",
              height: "auto",
              width: "7.99%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {Number(totalOpeningQty).toFixed(2)}
          </Text>

          <Text
            style={{
              padding: "2px",
              fontSize: "8px",
              height: "auto",
              width: "9.99%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "right",
            }}
          >
            {Number(totalOpeningAmt)?.toFixed(2)}
          </Text>
          <Text
            style={{
              padding: "2px",
              fontSize: "8px",
              height: "auto",
              width: "7.99%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {/* {Math.abs(totalReceiveQty)} */}
            {Number(totalReceiveQty).toFixed(2)}
          </Text>
          <Text
            style={{
              padding: "2px",
              fontSize: "8px",
              height: "auto",
              width: "9.99%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "right",
            }}
          >
            {Number(totalReceiveAmt)?.toFixed(2)}
          </Text>
          <Text
            style={{
              padding: "2px",
              fontSize: "8px",
              height: "auto",
              width: "7.99%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {/* {Math.abs(totalIssueQty)} */}
            {Number(totalIssueQty).toFixed(2)}
          </Text>
          <Text
            style={{
              padding: "2px",
              fontSize: "8px",
              height: "auto",
              width: "9.99%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "right",
            }}
          >
            {Number(totalIssueAmt)?.toFixed(2)}
          </Text>
          <Text
            style={{
              padding: "2px",
              fontSize: "8px",
              height: "auto",
              width: "8.99%",
              color: "green",
              fontWeight: "bold",
              fontFamily: "Times",
              borderRight: "1px solid black",
              textAlign: "center",
            }}
          >
            {/* {Math.abs(totalClosingQty)} */}
            {Number(totalClosingQty).toFixed(2)}
          </Text>
          <Text
            style={{
              padding: "2px",
              fontSize: "8px",
              height: "auto",
              width: "10.1%",
              color: "green",
              borderRight: "1px solid black",
              fontWeight: "bold",
              fontFamily: "Times",
              textAlign: "right",
            }}
          >
            {Number(totalClosingAmt)?.toFixed(2)}
          </Text>
        </View>

        {/* Signature part */}
        {/* <View
          wrap={false}
          fixed
          style={{
            flexDirection: "row",
            bottom: 0,
            marginTop: 60,
            justifyContent: "space-around",
          }}
        >
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Store Incharge
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Received Signature
              </Text>
            </View>
          </View>
          <View>
            <View style={{ textAlign: "center" }}>
              <Text
                style={{
                  backgroundColor: "black",
                  height: "1px",
                  width: "120px",
                }}
              ></Text>
              <Text style={{ fontSize: "9px", width: "120px" }}>
                Authorised Signature
              </Text>
            </View>
          </View>
        </View> */}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};

Font.registerHyphenationCallback((word) => {
  if (word.length > 16) {
    return chunkSubstr(word, 14);
  } else {
    return [word];
  }
});

Font.register({
  family: "Helvetica",
  fonts: [
    {
      src: "/assets/fonts/Helvetica.ttf",
      fontWeight: "normal",
    },
    {
      src: "/assets/fonts/Helvetica Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Times",
  fonts: [
    {
      src: "/assets/fonts/Times-Bold.ttf",
      fontWeight: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  imageContent: {
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  body: {
    paddingTop: 25,
    paddingBottom: 60,
    paddingHorizontal: 20,
    fontFamily: "Helvetica",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default Quixote;
